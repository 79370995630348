import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@views/Home.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/indexMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/indexMenu/index',
				name: '首页',
				component: () => import('@/views/indexMenu/index.vue')
			},	

		]
	},
	{
		path: '/userMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/userMenu/userList',
				name: '用户列表',
				component: () => import('@/views/userMenu/userList.vue')
			},	
			{
				path: '/userMenu/integralList',
				name: '积分调整记录',
				component: () => import('@/views/userMenu/integralList.vue')
			},

		]
	},
	{
		path: '/conentMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/conentMenu/yinsixieyi',
				name: '内容列表',
				component: () => import('@/views/conentMenu/yinsixieyi.vue')
			},	
			{
				path: '/conentMenu/banner',
				name: '轮播图',
				component: () => import('@/views/conentMenu/banner.vue')
			},
		]
	},	{
		path: '/activityMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/activityMenu/activityList',
				name: '活动管理',
				component: () => import('@/views/activityMenu/activityList.vue')
			},	
			{
				path: '/activityMenu/notesList',
				name: '笔记管理',
				component: () => import('@/views/activityMenu/notesList.vue')
			},

		]
	},
	{
		path: '/integralMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/integralMenu/goodsList',
				name: '商品管理',
				component: () => import('@/views/integralMenu/goodsList.vue')
			},	
			{
				path: '/integralMenu/orderList',
				name: '订单管理',
				component: () => import('@/views/integralMenu/orderList.vue')
			},

		]
	},




	{
		path: '/login',
		name: 'login',
		component: () => import('@views/login.vue')
	}
]

// 异步挂载的路由
// 动态需要根据权限加载的路由表
const asyncRouterMap = [
	{
		path: '/permission',
		component: Home,
		name: '权限测试',
		meta: { role: ['admin', 'super_editor'] }, // 页面需要的权限
		children: [
			{
				path: 'index',
				component: Home,
				name: '权限测试页',
				meta: { role: ['admin', 'super_editor'] } // 页面需要的权限
			}]
	},
	{ path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
	mode: 'history',
	routes,
	asyncRouterMap
})

console.log(router)

export default router
